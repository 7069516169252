<script>
import { defineComponent, ref, onMounted } from "vue"
import DropDownMenu from "./DropDownMenu.vue"
import HamburgerMenu from "./HamburgerMenu.vue"

export default defineComponent({
  components: { DropDownMenu, HamburgerMenu },
  setup() {
    // const scrolledNav = ref(null)
    const mobile = ref(false)
    const mobileNav = ref(false)
    const windowWidth = ref(null)
    function toggleMobileNav() {
      mobileNav.value = !mobileNav.value
    }
    function checkScreen() {
      windowWidth.value = window.innerWidth
      if (windowWidth.value <= 950) {
        mobile.value = true
        return
      }
      mobile.value = false
      mobileNav.value = false
      return
    }
    onMounted(() => {
      window.addEventListener("resize", checkScreen)
      checkScreen()
    })
    function contactClicked() {
      console.log("contact clicked")
    }
    return {
      mobile,
      mobileNav,
      windowWidth,
      toggleMobileNav,
      checkScreen,
      contactClicked,
    }
  },
})
</script>

<template>
  <div class="_nav-wrapper">
    <nav class="_nav">
      <div class="_nav-header">
        <a href="/">
          <img
            src="../assets/svg/foxconniai_logo.svg"
            alt="foxconn iai logo"
            class="_logo"
          />
        </a>
        <div class="_icon">
          <HamburgerMenu
            :open="mobileNav"
            @click="toggleMobileNav"
            v-show="mobile"
          />
        </div>
        <div v-show="!mobile" class="_nav-inner">
          <DropDownMenu kind="products" />
          <DropDownMenu kind="resources" />
          <a href="about" class="_about"><p>About</p></a>
        </div>
        <div v-show="!mobile" class="_buttons">
          <a href="signIn" class="outline-btn">Sign In</a>
          <a href="/contact" class="cta-btn" @click="contactClicked">
            Contact Us</a
          >
        </div>
      </div>
      <div class="_sidebar">
        <div
          class="_sidebar-backdrop"
          @click="mobileNav = false"
          v-if="mobileNav"
        ></div>
        <transition name="slide">
          <div v-if="mobileNav" class="_sidebar-panel">
            <DropDownMenu :sidebar="true" kind="products" />
            <DropDownMenu :sidebar="true" kind="resources" />
            <a href="about"><p class="_about" style="padding: 0">About</p></a>
            <div class="_buttons-sidebar">
              <a href="signIn" class="outline-btn"><p>Sign In</p></a>
              <a href="/contact" class="cta-btn"><p>Contact Us</p></a>
            </div>
          </div>
        </transition>
      </div>
    </nav>
  </div>
</template>
<style lang="sass" scoped>
@import '../styles/colors'
._nav-wrapper
  position: fixed
  top: 0
  left: 0
  width: 100%
  color: #fff
  background-color: $c-navy
  transition: 500ms ease all
  z-index: 99
._nav
  position: relative
  display: flex
  height: 5.5vh
  min-height: 64px
  transition: 500ms ease all
  margin: 0 auto
  max-width: 1600px
  &-header
    display: flex
    flex: 1
    justify-content: center
    align-items: center
    width: 100%
    margin: 1em 0
._nav-inner
  display: flex
  align-items: center
  margin: 0 auto
  max-width: 600px
  gap: 30px
  &:deep(>*)
    flex: 1
    display: flex
    justify-content: center

._logo
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  min-height: 26px
._about
  text-decoration: none
  color: #fff
  font-weight: 500
  transition: all 0.3s ease
  padding: 0.5em 0.75em
  border-radius: 3px
  max-width: max-content
  &:hover
    background-color: $c-blue-functional

._icon
  display: flex
  align-items: center
  height: 100%
  z-index: 99

._sidebar
  // &-backdrop
  //   background-color: rgba(19, 15, 64, .4)
  //   width: 100vw
  //   height: 100%
  //   position: fixed
  //   top: 0
  //   left: 0
  //   cursor: pointer
  &-panel
    overflow-y: auto
    position: fixed
    left: 0
    right: 0
    top: 64px
    bottom: 0
    display: flex
    flex-direction: column
    justify-content: flex-start
    align-items: flex-start
    gap: 1em
    background-color: $c-navy
    padding: 0 2em
    padding-top: 1em
    z-index: 999
._buttons
  display: flex
  align-items: center
  gap: 2em

._buttons-sidebar
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  margin-top: 4em
  gap: 1em

.slide-enter-active,
.slide-leave-active
  transition: transform 0.2s ease


.slide-enter,
.slide-leave-to
  transform: translateX(-100%)
  transition: all 150ms ease-in 0s

@media only screen and (max-width: 1648px)
  nav
    padding: 0 2em
@media only screen and (max-width: 950px)
  ._nav
    &-header
      justify-content: space-between
</style>
