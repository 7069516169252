import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import VueGtag from "vue-gtag"

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import "../scss/custom.css"
import "./assets/css/main.css"
import "./styles/global.sass"
import "./styles/colors.sass"
import "./styles/typography.sass"
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css"

Vue.use(VueGtag, {
  config: { id: "G-RGJXQN9PLK" },
})

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app")
