<template>
  <span>
    <div v-if="!sidebar">
      <div
        v-if="kind === 'products'"
        class="_custom-select"
        @mouseenter="open = true"
        @mouseleave="open = false"
      >
        <div
          :class="[open ? '_open' : '', '_dropdown-title']"
          @click="open = !open"
        >
          <p class="_title">Products</p>
          <img
            :class="[open ? '_rotate-180' : '']"
            style="width: 13px; margin-left: 6px"
            src="../assets/svg/dropdown-icon.svg"
            alt="dropdown icon"
          />
        </div>
        <Transition name="slide">
          <div v-if="open" class="_items-wrapper">
            <div class="_items">
              <a href="/optimo" style="display: flex">
                <img
                  style="max-height: 26px; margin-right: 10px"
                  src="../assets/svg/optimo-icon.svg"
                  alt="Optimo icon"
                />
                <p class="medium">OPTIMO</p>
              </a>
            </div>
          </div>
        </Transition>
      </div>
      <div
        v-if="kind === 'resources'"
        class="_custom-select"
        @mouseenter="open = true"
        @mouseleave="open = false"
      >
        <div
          class=""
          :class="[open ? '_open' : '', '_dropdown-title']"
          @click="open = !open"
        >
          <p class="_title">Resources</p>
          <img
            :class="[open ? '_rotate-180' : '']"
            style="width: 13px; margin-left: 10px"
            src="../assets/svg/dropdown-icon.svg"
            alt="dropdown icon"
          />
        </div>
        <Transition name="slide">
          <div v-if="open" class="_items-wrapper">
            <div class="_items">
              <a href="/integration"><p class="medium">Integration</p></a>
              <a href="/faq" style="padding-left: 1em; padding-right: 0"
                ><p class="medium">FAQ</p></a
              >
              <a href="/case-study" style="padding-left: 1em; padding-right: 0"
                ><p class="medium">Case Study</p></a
              >
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div v-if="sidebar" class="w-full">
      <div v-if="kind === 'products'">
        <div
          style="display: flex"
          :class="[open ? open : '', '_dropdown-title-sidebar']"
          @click="open = !open"
        >
          <p class="_title">Products</p>
          <img
            :class="[open ? '_rotate-90' : '']"
            style="width: 20px"
            src="../assets/svg/dropdown-icon-sidebar.svg"
            alt="dropdown icon"
          />
        </div>
        <Transition name="slide">
          <div v-if="open" class="_items-in-sidebar">
            <a href="/optimo" class="_products">
              <img
                style="max-height: 26px; margin-right: 10px"
                class="_products-icon"
                src="../assets/svg/optimo-icon.svg"
                alt="Optimo Icon"
              />
              <p class="medium">OPTIMO</p>
            </a>
          </div>
        </Transition>
      </div>
      <div v-if="kind === 'resources'" style="width: 100%">
        <div
          style="display: flex"
          :class="[open ? open : '', '_dropdown-title-sidebar']"
          @click="open = !open"
        >
          <p class="_title">Resources</p>
          <img
            :class="[open ? '_rotate-90' : '']"
            style="width: 20px"
            src="../assets/svg/dropdown-icon-sidebar.svg"
            alt="dropdown icon"
          />
        </div>
        <Transition name="slide">
          <div v-if="open" class="_items-in-sidebar">
            <a class="_resources" href="/integration">Integration</a>
            <a
              class="_resources"
              href="https://iaiinstitute.com/"
              target="_blank"
              >E-Learning</a
            >
            <a class="_resources" href="/faq">FAQ</a>
            <a class="_resources" href="/case-study">Case Study</a>
          </div>
        </Transition>
      </div>
    </div>
  </span>
</template>

<script>
import { defineComponent, ref } from "vue"

export default defineComponent({
  props: {
    /**
     * @example
     * "products" | "resources"
     */
    kind: { type: String, default: "products" },
    sidebar: { type: Boolean, default: false },
  },
  setup(props) {
    const open = ref(false)
    return {
      open,
    }
  },
})
</script>

<style lang="sass" scoped>
@import '../styles/colors'
._custom-select
  position: relative
  text-align: left
  outline: none
  font-size: 1rem
  text-decoration: none

._dropdown-title
  display: flex
  justify-content: center
  align-items: center
  font-weight: bold
  color: #fff
  padding: 0.5em 2em
  cursor: pointer
  user-select: none
  border-radius: 3px
  &:hover
    background-color: $c-blue-functional
._open
  background-color: $c-blue-functional
._dropdown-title-sidebar
  display: flex
  justify-content: space-between
  font-weight: bold
  color: #fff
  cursor: pointer
  user-select: none
  padding: 0.25em 0

._items-wrapper
  all: unset
  position: absolute
  left: 0
  right: 0
  top: 100%
  padding-top: 10px
._items
  background-color: $c-navy
  display: flex
  flex-direction: column
  font-weight: medium
  color: #fff
  z-index: 1
  a
    display: flex
    color: #fff
    text-decoration: none
    text-align: start
    padding: 0.5em 1em
    cursor: pointer
    user-select: none
    &:hover
      background-color: #2A3A72

._items-in-sidebar
  display: flex
  flex-direction: column
  background-color: transparent

._products,
._resources
  all: unset
  display: flex
  align-items: center
  padding: 1em 0
  &-icon
    height: 30px

._title
  font-weight: 500

._rotate-180
  transform: rotate(180deg)
  transition: 0.3s linear

._rotate-90
  transform: rotate(90deg)
  transition: 0.3s linear

.slide-enter-active
  -moz-transition-duration: 0.3s
  -webkit-transition-duration: 0.3s
  -o-transition-duration: 0.3s
  transition-duration: 0.3s
  -moz-transition-timing-function: ease-in-out
  -webkit-transition-timing-function: ease-in-out
  -o-transition-timing-function: ease-in-out
  transition-timing-function: ease-in-out

.slide-leave-active
  -moz-transition-duration: 0.3s
  -webkit-transition-duration: 0.3s
  -o-transition-duration: 0.3s
  transition-duration: 0.3s
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1)

.slide-enter-to,
.slide-leave
  max-height: 100px
  overflow: hidden

.slide-enter,
.slide-leave-to
  overflow: hidden
  max-height: 0
</style>
