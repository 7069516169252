<template>
  <div class="home">
    <Banner />
    <div class="body-home py-5">
      <div class="container product-wrapper">
        <div class="intro row justify-content-center py-2 my-5 mx-3">
          <p
            style="text-align: center"
            class="h2 col-lg-12 py-3 catch-phrase text-capitalize"
          >
            We provide intelligent software solutions for process optimization
          </p>
        </div>

        <div class="row products my-5">
          <div class="col-lg-4 pb-3 card">
            <img
              alt="Time and Motion Studies"
              class="product img-icon"
              src="@/assets/svg/optimo_full_logo.svg"
            />
            <h4 class="product-headline">Optimized Time & Motion Studies</h4>
            <p class="my-3 product-text">
              An AI powered software solution that digitizes human operations
              for performance measurements such as time and motion studies,
              analytical assessments, and process optimization.
            </p>
            <p>
              <a
                class="btn btn-outline-light product-btn my-3"
                href="optimo"
                role="button"
                >Learn More</a
              >
            </p>
          </div>
          <img
            v-if="false"
            class="product"
            src="@/assets/products/foxee.png"
            alt="Foxee logo"
          />
        </div>
        <button v-if="false" class="pricing">Pricing</button>
      </div>
      <div class="col center my-5">
        <a type="button" href="contact" class="cta-btn-content cta-btn"
          >Contact Us</a
        >
      </div>
      <Partners />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner.vue"
import Partners from "@/components/Partners.vue"

export default {
  name: "Home",
  components: {
    Banner,

    Partners,
  },
}
</script>

<style scoped>
.product img {
  width: 100px;
}
.products > .card {
  width: 40%;
  padding: 60px 30px 5vw 60px;
  position: relative;
  border-radius: 20px;
  margin: 0 2.5%;
  background: linear-gradient(
    89deg,
    #00153dc2 0%,
    #00153d87 60%,
    rgba(89, 143, 193, 0) 100%
  );
  border: none;
}
.img-icon {
  position: absolute;
  top: -46px;
  left: 50px;
}
.product-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-gap: 4rem;
}
@media only screen and (max-width: 800px) {
  .products > .card {
    width: 100%;
    padding-top: 3em;
    padding-left: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .products {
    width: 100%;
  }
  .products > .card:nth-child(1) {
    margin-bottom: 150px;
  }
  .img-icon {
    width: 60%;
    top: -46px;
    left: 50px;
  }
  .intro {
    position: relative;
  }
  .intro::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 1px;
    background-color: #32e39e;
    bottom: -20px;
    right: 0px;
  }
  .intro::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 1px;
    background-color: #32e39e;
    top: -20px;
    left: 0px;
  }
}
.product-headline {
  color: #05d79d;
}
.temp-title-container {
  padding-top: 55px;
}
.introducing {
  font-size: x-large;
  letter-spacing: 2px;
  font-weight: 300;
}
.learn-more a {
  color: inherit;
  text-decoration: none;
}
.learn-more {
  font-size: x-large;
  text-decoration: none;
  font-weight: 600;
}
.institute .container {
  backdrop-filter: blur(4px);
}
.institute-slogan {
  font-size: x-large;
  font-weight: 200;
  text-shadow: 4px 4px 8px white;
}
.institute img {
  width: 40%;
  margin-top: 5%;
}
.institute .container {
  padding: 5%;
}
.institute {
  height: 100%;
  min-height: 100vh;
  background-image: url("../assets/codeblackandwhite.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: white;
}
.product-text {
  color: #fff;
  font-weight: 300;
  line-height: 2rem;
}

.fw-200 {
  font-weight: 200;
}
h4 {
  font-weight: 200;
}
.data-challenge {
  width: 100%;
}
.pricing {
  margin-top: 100px;
  padding: 10px 35px 10px 35px;
  letter-spacing: 0.15rem;
  cursor: pointer;
  background-color: #03a9fc;
  border: none;
  color: white;
  font-size: 20px;
  margin-bottom: 100px;
}
.product {
  height: 5rem;
  opacity: 0.9;
}

.products {
  display: flex;
  justify-content: space-evenly;
  text-align: left;
}

.products-heading {
  max-width: 60%;
  font-weight: 200;
}
.center-logo {
  padding-top: 150px;
}
.body-home {
  color: white;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: hsl(218deg 90% 16% / 85%);
  background-image: radial-gradient(
      at 40% 20%,
      hsl(217deg 85% 27%) 0px,
      transparent 50%
    ),
    radial-gradient(at 88% 64%, hsl(226deg 64% 20% / 96%) 0px, transparent 50%),
    radial-gradient(at 21% 30%, hsl(220deg 46% 14%) 0px, transparent 50%);
  backdrop-filter: blur(4px);
  font-family: "Poppins";
}

.home {
  background-image: url("../assets/folo1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media only screen and (max-width: 768px) {
  .img-icon {
    width: 60%;
    height: auto;
    top: -60px;
  }
  .institute img {
    width: 85%;
  }
  .institute .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h3 {
    font-size: calc(2.5rem * var(--smaller));
  }
  .products-heading {
    max-width: 100%;
    font-size: 0.8rem;
  }
  .catch-phrase {
    font-size: medium;
    line-height: 1.5rem;
  }
  .product-headline {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .product-text {
    line-height: 2rem;
  }
  .product-btn {
    width: 100%;
    padding: 0.75em;
  }
  .products > .card {
    margin: 0;
  }
}

@media only screen and (max-width: 428px) {
  .product-wrapper {
    width: 90%;
    padding: 2em 2.5%;
  }
}
</style>
