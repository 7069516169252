<template>
  <div class="_banner-container">
    <!-- Announcement Banner -->

    <!-- OPTIMO TimerPro Integration Popup -->

    <div
      class="alert _alert-opt alert-dismissible d-flex fade show"
      role="alert"
    >
      <div class="_alert-head my-3">
        <div class="col">
          <img
            src="../assets/integration/iAIoptimo_logo.png"
            alt="Optimo Foxconn IAI Logo"
          />
        </div>
        <div class="mx-3"><i class="bi bi-x-diamond-fill"></i></div>
        <div class="col">
          <img
            src="../assets/integration/ACSTimerPro_logo.png"
            alt="ACS Timer Pro Logo"
          />
        </div>
      </div>
      <h5 class="fw-bold">Already utilizing Timer Pro?</h5>
      <p>See how OPTIMO uses AI to take your data further.</p>
      <a href="/integration" class="btn btn-opt btn-sm my-2">Learn More</a
      ><a href="/integration" class="link-m">Learn More</a>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>

    <img src="@/assets/svg/scroll.svg" class="_scroll-tip" alt="scroll icon" />
    <div class="_texture"></div>
    <video
      autoplay
      muted
      loop
      playsinline
      class="banner-video"
      src="@/assets/videos/compressed-web-banner-m.mp4"
    >
      Your browser does not support HTML5 video.
    </video>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import objectFitVideos from "./object-fit-video"

export default defineComponent({
  setup() {
    objectFitVideos()
    return { objectFitVideos }
  },
})
</script>
<style lang="sass" scoped>

/* ////////////////
  OPTIMO TimerPro Integration Popup
  //////////////// */

._alert-banner
  flex-direction: row
  justify-content: center
  width: 100vw
  color: #fff
  background-color: #00000094
  border-radius: unset
  position: fixed
  top: 64px
  padding: 3em 0
  font-size: 2rem
  z-index: 30
  overflow-y: hidden
  & div
    margin-right: .5rem
  @media only screen and (max-width: 950px)
      font-size: 1rem
      padding: 2.5rem .5rem 2.5rem 1rem
  @media only screen and (max-width: 425px)
      justify-content: flex-start
      flex-direction: column
      gap: .3rem
  .link-m
      display: none
      color: #fff
      padding-left: 1rem
      text-decoration: underline
      @media only screen and (max-width: 425px)
          display: block
  .btn
      @media only screen and (max-width: 425px)
          display: none
  &.alert-dismissible
      ._close-btn
          position: absolute
          right: 0
          top: 0
          color: #fff
          padding: .5rem 1.5rem
          &:hover
              opacity: .75
          @media only screen and (max-width: 950px)
              padding: 0rem 1.5rem
          @media only screen and (max-width: 425px)
              padding: 0 rem 1rem
          @media only screen and (max-width: 280px)
              padding: 0rem .3rem

._alert-opt
  position: absolute
  right: 2em
  bottom: 2em
  z-index: 20
  max-width: 30em
  flex-direction: column
  background-image: url('@/assets/integration/OPT-TP-INT-pop.png')
  border-radius: .8rem
  color: var(--c-navy)
  padding-inline: 1.5rem
  ._alert-head
    display: flex
    flex-direction: row
    justify-content: space-around
    align-items: center
    max-width: 100%
    margin-inline: .5rem
    color: var(--c-blue)

  img
    max-width: 100%
    display: block
  .col:nth-child(2)
    margin-left: .6rem
  p
    margin-bottom: 1rem
    font-size: medium
    line-height: 1.3rem
    font-weight: 500
  .btn-opt
    background-color: #2453BC
    color: #fff
    font-weight: 500
    width: fit-content
    &:hover
      background-color: #3867D0
  .btn-close
    &:focus
      box-shadow: none
  @media only screen and (max-width: 950px)
    position: relative
    margin: auto
    right: unset
    bottom: unset


/* ////////////////
  General Styling
  //////////////// */
.link-m
  display: none

._banner-container
  display: flex
  position: relative
  height: 33vw
  min-height: 95vh
  margin-top: 5.5vh
  width: 100vw
  background-color: #050a50
  background-repeat: no-repeat
  background-size: cover
  background-attachment: fixed
  flex-direction: column
  justify-content: center
  align-items: center
  color: white
  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(to bottom, rgba(255,0,0,0) 70%,rgba(1, 30, 77, 1) 100%)
    z-index: 10
._scroll-tip
  position: absolute
  left: 50%
  bottom: 8%
  -webkit-transform: translateX(-50%)
  transform: translateX(-50%)
  z-index: 20
  width: 35px
  -webkit-animation: float 3s infinite
  animation: float 3s infinite
@media only screen and (max-width: 768px)
  ._scroll-tip
    display: none

  ._banner-container
    margin-top: 5vh
    height: 75vh
    min-height: 45vh


@-webkit-keyframes float
  0%
    -webkit-transform: translate(-50%, 0)
    transform: translate(-50%, 0)


  50%
    -webkit-transform: translate(-50%, 15px)
    transform: translate(-50%, 15px)


  100%
    -webkit-transform: translate(-50%, 0)
    transform: translate(-50%, 0)


@keyframes float
  0%
    -webkit-transform: translate(-50%, 0)
    transform: translate(-50%, 0)


  50%
    -webkit-transform: translate(-50%, 15px)
    transform: translate(-50%, 15px)


  100%
    -webkit-transform: translate(-50%, 0)
    transform: translate(-50%, 0)

.banner-video
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  object-fit: cover
  font-family: 'object-fit: cover'

._texture
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 15
  opacity: 0.45
  background: url('@/assets/overlay_pattern.png')
</style>
