<template>
  <div id="app">
    <TopNavBar />
    <router-view style="margin-top: 64px" />
    <CustomFooter />
  </div>
</template>
<script>
import TopNavBar from "@/components/TopNavBar"
import CustomFooter from "@/components/CustomFooter"
export default {
  components: {
    CustomFooter,
    TopNavBar,
  },
  data() {
    return {}
  },
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

body
  margin: 0
  font-family: "Inter" !important


#app
  font-family: Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: "Inter" !important
  color: #fff
  height: 100%
</style>
