<template>
  <div class="partners">
    <div class="our-partners d-flex flex-column justify-items-center">
      <div style="gap: 100px" class="text-center">
        <p class="h2">Our Partners</p>
      </div>
      <div class="partner-container">
        <a href="#" class="partner-logo"
          ><img class="partner" src="@/assets/partners/WEF.png" alt="WEF logo"
        /></a>
        <a href="#" class="partner-logo"
          ><img class="partner" src="@/assets/partners/IMS.png" alt="IMS logo"
        /></a>
        <a href="#" class="partner-logo"
          ><img
            class="partner"
            src="@/assets/partners/CyberInsight.png"
            alt="CyberInsight logo"
        /></a>
        <a href="#" class="partner-logo"
          ><img
            class="partner"
            src="@/assets/partners/Minth_Logo.png"
            alt="Minth logo"
        /></a>
        <a href="#" class="partner-logo"
          ><img class="partner" src="@/assets/partners/Fii.png" alt="Fii logo"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      context: null,
    }
  },
}
</script>
<style scoped>
.partner {
  height: 5em;
}
a.partner-logo img {
  opacity: 0.3;
  transition: all 1s ease;
}
a.partner-logo img:hover {
  opacity: 1;
}
a.partner-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
.partner-container {
  margin-top: 2em;
  padding-top: 4em;
  padding-bottom: 4em;
  gap: 1em;
  /* height: 50vh; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.our-partners h1,
p {
  margin-top: 0;
  margin-bottom: 0;
}
.our-partners {
  padding-top: 4em;
  padding-bottom: 3em;
}
@media only screen and (max-width: 428px) {
  .our-partners {
    padding-top: 4em;
    padding-bottom: 0;
  }
}
.partners {
  width: 100%;
  height: 100%;
  position: relative;
  color: white;
  /*background-color: hsl(218deg 90% 16% / 100%);
	background-image:
	radial-gradient(at 40% 20%, hsl(217deg 69% 16%) 0px, transparent 50%),
	radial-gradient(at 88% 64%, hsla(268,82%,43%,0.96) 0px, transparent 50%),
	radial-gradient(at 21% 30%, hsla(220,82%,52%,1) 0px, transparent 50%);*/
  /*background-image: url("../assets/partners/background.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-size: 100% 100%;*/
}

@media only screen and (max-width: 800px) {
  .partner {
    width: 70%;
    height: auto;
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .partner-container {
    height: 100%;
  }
}
</style>
