import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/optimo",
    name: "OPTIMO",
    component: () => import("../views/Optimo.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/case-study",
    name: "case study",
    component: () => import("@/views/CaseStudy.vue"),
  },
  {
    path: "/integration",
    name: "integration",
    component: () => import("@/views/Integration.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("../views/faq.vue"),
  },
  {
    path: "/careers",
    name: "Careers",
    component: () => import("../views/Careers.vue"),
  },
  {
    path: "/signIn",
    name: "signIn",
    component: () => import("../views/signIn.vue"),
  },
  {
    path: "/dev",
    name: "Dev",
    component: () => import("../views/Dev.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

export default router
